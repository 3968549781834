




































































import { Component, Vue } from "vue-property-decorator";
import Card from "./components/card.vue";
import ImageLoad from "@/app/shared/components/file-preview/image-load.vue";
import ElHover from "@/app/shared/components/file-preview/el-hover.vue";

import MediaFileModel from "@/app/domains/settings/views/media/models/media-file.model";

import DocumentFileService from "@/app/shared/services/document-file.service";
import DateWUtc from "@/app/shared/utils/date-w-utc";
import Date from "@/app/shared/utils/date";
import CopyText from "@/app/shared/utils/copy-text";

enum MenuItemEnum {
  copy = "copy",
  goto = "goto",
  delete = "delete",
}

type MenuItem = {
  command: MenuItemEnum;
  title: string;
  icon: string;
};

@Component({
  components: { Card, ImageLoad, ElHover },
  filters: {
    DateWUtc,
    Date,
  },
})
export default class Media extends Vue {
  private files: Array<MediaFileModel> = [];

  private menuItems: Array<MenuItem> = [
    { command: MenuItemEnum.copy, title: "Copy link", icon: "link" },
    { command: MenuItemEnum.goto, title: "Go to location", icon: "document" },
    { command: MenuItemEnum.delete, title: "Remove", icon: "delete" },
  ];

  private menuClick(command: MenuItemEnum, file: MediaFileModel): void {
    switch (command) {
      case MenuItemEnum.delete:
        this.deleteFile(file);
        break;

      case MenuItemEnum.goto:
        window.open(file.url);
        break;

      case MenuItemEnum.copy:
        if (file.url) CopyText(file.url);
        break;

      default:
        break;
    }
  }

  private async deleteFile(file: MediaFileModel): Promise<void> {
    try {
      await DocumentFileService.deleteFile(file.id);
      this.files = this.files.filter((f) => f.id !== file.id);
    } catch (error) {
      console.error(error);
    }
  }

  async created(): Promise<void> {
    try {
      const resp = await DocumentFileService.getFiles();
      this.files = resp.data;
    } catch (error) {
      console.error(error);
    }
  }
}
