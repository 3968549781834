export default (value: string, showTime = true, delimiter = ","): string => {
  if (!value) return value;
  const date = new Date(value);
  const localization = ["en-GB"];

  let dateForView = date.toLocaleDateString(localization);
  if (showTime) {
    dateForView += `${delimiter} ${date.toLocaleTimeString(localization, {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }
  return dateForView;
};
