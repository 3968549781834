export default async (value: string): Promise<void> => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(value);
  } else {
    const textarea = document.createElement("textarea");
    textarea.textContent = value;
    textarea.style.opacity = "0";
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
    } catch (error) {
      console.warn("Copy to clipboard failed.", error);
    } finally {
      document.body.removeChild(textarea);
    }
  }
};
