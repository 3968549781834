import Http from "@/app/shared/http";
import { HttpConfig } from "@/app/shared/http/http";
import { AxiosResponse } from "axios";

import FileModel from "@/app/shared/models/file.model";
import MediaFileModel from "@/app/domains/settings/views/media/models/media-file.model";

export const uploadFile = (
  id: number,
  data: FormData
): Promise<AxiosResponse<FileModel>> =>
  Http.request<FileModel>(
    new HttpConfig({
      url: `DocumentFile/${id}`,
      method: "post",
      data,
    })
  );

export const getFiles = (): Promise<AxiosResponse<Array<MediaFileModel>>> =>
  Http.request<Array<MediaFileModel>>(
    new HttpConfig({
      url: `DocumentFile`,
      method: "get",
    })
  );

export const deleteFile = (id: number): Promise<AxiosResponse<void>> =>
  Http.request(
    new HttpConfig({
      url: `DocumentFile/${id}`,
      method: "delete",
    })
  );

export default {
  uploadFile,
  getFiles,
  deleteFile,
};
