




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ImageLoad extends Vue {
  @Prop({ type: String, required: true }) src!: string;

  $refs!: {
    image: HTMLImageElement;
  };

  async mounted(): Promise<void> {
    const img = new Image();
    img.src = this.src;
    img.onload = () => {
      if (this.$refs.image) {
        if (img.width > 2000 || img.height > 2000) {
          this.$refs.image.src = "/images/image-too-big.jpg";
          return;
        }
        this.$refs.image.src = this.src;
      }
    };
  }
}
